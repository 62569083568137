body {
    background: #f4f4f6 !important;
}

.admin-container {
    max-width: 95%;
    width: 95%;
}

.admin-login-bg {
    /* margin-top: 9%; */
    padding: 15px;
    margin-bottom: 25px;
    border-radius: 10px;
}

.admin-navbar .navbar-brand {
    width: 16%;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
}

.admin-navbar .navbar-light .navbar-nav .nav-link {
    color: #7e90a1;
    letter-spacing: 0.3px;
    padding: 20px 0px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 0;
}

textarea.text-area {
    height: auto !important;
}

.admin-navbar .active a {
    color: #000 !important;
    border-bottom: #044d7f solid 3px;
    border-radius: 0px;
}

.admin-navbar li a:hover {
    color: #000;
    border-bottom: #044d7f solid 3px;
    border-radius: .25rem;
}

.admin-navbar .nav {
    width: 100%;
}

.admin-navbar .navbar {
    padding: 0rem 15px;
}

.admin-navbar .navbar-brand {
    padding-bottom: 0;
}

.admin-navbar .navbar-nav {
    margin-bottom: -19px;
}

.logout img {
    float: right;
    margin-left: 10px;
}

.logout a {
    background: #044d7f !important;
    padding: 8px 10px !important;
    display: flex;
    color: #fff !important;
    font-weight: 500;
    border-bottom: none !important;
}

.logout {
    list-style: none;
    margin-bottom: 0px;
}

.logout a:hover {
    color: #fff !important;
    border-radius: 3px;
    border-bottom: none !important;
    background: #818081 !important;
}

.admin-title h1 {
    color: #2d2d2d;
    margin: 15px 0 0;
    font-size: 30px;
    padding: 0;
}

.card {
    box-shadow: hsl(0deg 0% 86%) 0 0 16px;
    background-color: hsl(0, 0%, 100%);
    border-radius: 7px;
    padding: 16px;
    border: none;
    margin-top: 15px;
    margin-bottom: 15px;
}

.table-bordered td {
    border: 1px solid #dee2e6 !important;
}

.table-bordered td:last-child {
    border-right: 1px solid #dee2e6 !important;
}

.table-responsive {
    overflow-x: auto
}

.admin-table th {
    border-bottom: none !important;
    background: #044d7f !important;
    color: #fff;
    text-align: left;
    padding: 5px 6px;
    font-size: 13px;
    font-weight: normal;
    border-color: #044d7f;
}

.admin-table td {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 5px;
}

.admin-table .form-control {
    height: auto !important;
    padding: 4px 10px;
    font-size: 13px;
    border-radius: 3px;
}

.admin-table label {
    font-size: 14px;
    margin-bottom: 0;
}

.admin-table .form-group {
    margin-bottom: 5px;
}

.edit-btn {
    background: #2db67e;
    font-size: 14px;
    font-weight: 500;
    display: block;
    overflow: hidden;
    border-radius: 3px;
    padding: 6px 10px;
    color: #ffffff !important;
    text-align: center;
    cursor: pointer;
    margin-bottom: 5px;
}

.edit-btn:hover {
    background: #818081;
    color: #fff;
    text-decoration: none;
}

.inline-btn {
    display: inline !important;
}

.admin-logout-btn {
    background: #044d7f !important;
    padding: 8px 10px !important;
    display: flex;
    color: #fff !important;
    font-weight: 500;
    border-bottom: none !important;
}

.admin-logout-btn:hover {
    color: #fff !important;
    border-radius: 3px;
    border-bottom: none !important;
    background: #818081 !important;
}

.trans-button {
    background: #2db67e;
    font-size: 14px;
    font-weight: 500;
    display: block;
    overflow: hidden;
    border-radius: 3px;
    padding: 6px 10px;
    color: #ffffff !important;
    text-align: center;
    cursor: pointer;
    margin-bottom: 5px;
}

.trans-button:hover {
    background: #818081;
    color: #fff;
    text-decoration: none;
}

.sub-navbar {
    float: right;
    width: 100%;
    margin-top: 20px;
}

.sub-navbar ul {
    padding: 0;
    margin: 0;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

.sub-navbar ul li {
    list-style: none;
    float: left;
}

.sub-navbar ul li a {
    font-size: 15px;
    background: #818081;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}

.sub-navbar ul li a:hover {
    text-decoration: none;
    background: #044d7f;
}

.sub-navbar ul li a.active {
    background: #044d7f;
}

.sub-user-navbar {
    float: right;
    width: 100%;
    margin-top: 20px;
}

.sub-user-navbar ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.sub-user-navbar ul li {
    list-style: none;
    float: left;
}

/* .down-btn:hover {
    display: none;
} */

.sub-user-navbar ul li a {
    font-size: 15px;
    background: #818081;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
}

.sub-user-navbar ul li a:hover {
    text-decoration: none;
    background: #044d7f;
}

.sub-user-navbar ul li a.active {
    background: #044d7f;
}

.float-lt {
    margin-left: 15px;
    color: red;
}

.form-control-check {
    width: auto;
    float: left;
}

.table-copy {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table-copy td {
    padding: .75rem;
    vertical-align: top;
}

.admin-table .btn-primary {
    background: #044d7f;
    border-color: #044d7f;
    font-size: 15px;
    padding: 8px 15px;
    font-weight: 500;
    border-radius: .25rem !important;
    color: #ffffff;
    border: 0px !important;
    margin: .25rem;
}

.admin-table .btn-secondary {
    background: #818081;
    border-color: #818081;
    font-size: 15px;
    padding: 8px 15px;
    font-weight: 500;
    border-radius: .25rem !important;
    color: #ffffff;
    border: 0px !important;
    margin: .25rem;
}

.align-ct {
    display: flex;

    justify-content: center;
}

.add-btn {
    background: #2db67e;
    color: #fff;
    padding: 5px 15px;
    float: right;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    border-radius: 3px;
}

.add-btn:hover {
    background: #818081;
    color: #fff;
}

.gray-bg {
    background: #f3f3f4;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.admin-popup .modal-header {
    background: #044d7f;
}

.admin-popup .modal-header h5 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.admin-popup .modal-body h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.admin-popup .modal-body li {
    font-size: 15px;
}

.admin-popup .form-control {
    font-size: 13px;
}

.modal-footer button {
    font-size: 14px;
    font-weight: 500;
}

.admin-popup label {
    font-size: 15px;
}

.admin-popup .close {
    color: #fff;
    opacity: 1;
}

.mt-10 {
    margin-top: 10px;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.overall strong {
    font-weight: 500;
    font-size: 14px;
    padding: 10px 0 !important;
}

.center-td {
    color: #000;
    font-weight: 500;
}

.last-td td {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.overall td {
    padding: 6px;
}

.overall th {
    font-size: 14px;
    font-weight: 500;
}

.overall h1 {
    background: #044d7f;
    font-size: 28px;
    text-align: center;
    color: #fff;
    margin: 0;
    padding: 6px 0;
    border-bottom: rgba(255, 255, 255, 0.4) solid 1px;
}

.activation-fa{
    font-size: 3em;
}
.PartiallyregisterUserColorCode{
    margin-top: 4px !important;
    font-size: 14px;
}
.custom-td{
    display: flex;
}
.custom-td span{
    margin-top: 5px;
}
.css-7x9vt0-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active, .css-4he1br.Mui-active, .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active{
    color: #fff !important;
}
.css-7x9vt0-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon, .css-4he1br.Mui-active .MuiTableSortLabel-icon, .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon{
    color: #fff !important;
    background: transparent !important;
}
.css-7x9vt0-MuiButtonBase-root-MuiTableSortLabel-root:hover .MuiTableSortLabel-icon, .css-4he1br:hover .MuiTableSortLabel-icon, .MuiButtonBase-root.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon{
    background: transparent !important;
}
.MuiTableCell-root .MuiTableSortLabel-root:hover{
    color: #fff !important;
}
.MuiTableCell-root.MuiTableCell-head div{
    display: block !important;
}
.MuiTablePagination-selectLabel {
    display: none;
}
.MuiIconButton-root, .MuiTypography-caption {
    color: #044c7e !important;
}
.MuiTablePagination-input{
    margin: 0 !important;
}
.MuiToolbar-regular{
    padding-right: 8px !important;
}
.MuiTablePagination-toolbar.MuiToolbar-regular{
    padding: 0 !important;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[aria-label="Quick Search"]{
    color:#000; 
} 
.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall[aria-label="clear"]{
    color:#044c7e; 
} 
.MuiButtonBase-root.Mui-disabled.MuiIconButton-root[aria-label="Clear Search"]{
    padding: 12px !important;
}
.fileWrapper {
    display: flex;
    justify-content: start;
    padding: 48px;
}
.fileWrapper .required{
    color: #cd5c5c;
    font-weight: 600;
}
.fileWrapper .error{
    margin-top: 8px;
}
.uploadCSV button{
    margin: 32px 0 0 24px;
}